const state = {
  fibrillationData: {
    pFib: 0.0,
  },
  coincidenceData: {
    probabilityOfCoincidence: 0.0,
    probabilityOfFault: 0.0,
    probabilityOfContact: 0.0,
  },
  // chartData: {
  //   datasets: [
  //     {
  //       label: 'Argonium',
  //       borderColor: '#F37B28',
  //       showLine: true,
  //       fill: false,
  //       backgroundColor: '#F37B28',
  //       cubicInterpolationMode: 'monotone',
  //       data: []
  //     }
  //   ]
  // },
  allowableVoltageCurvePoints: [],
}

const mutations = {
  setProbFib(state, pFib) {
    state.fibrillationData.pFib = pFib
  },
  setProbCoinc(state, pCoinc) {
    state.coincidenceData.probabilityOfCoincidence = pCoinc
  },
  setProbFault(state, pFault) {
    state.coincidenceData.probabilityOfFault = pFault
  },
  setProbContact(state, pContact) {
    state.coincidenceData.probabilityOfContact = pContact
  },
  // setChartData (state, chartData) {
  //   state.chartData = chartData
  // },
  clearDataPoints(state) {
    state.allowableVoltageCurvePoints = []
  },
  setPoints(state, points) {
    state.allowableVoltageCurvePoints = points
  },
}

export default {
  namespaced: true,
  // modules: {
  //   results,
  //   inputData
  // },
  state,
  // getters,
  // actions,
  mutations,
}
