import arRisk from '@/comms/ArgonRisk.js'

const getErrorMessage = function (errorCode, errorMessage) {
  switch (errorCode) {
    // Can't reach server
    case 0:
    case 404: // Incorrect endpoint name etc
      return 'Comms Error'
    case 401:
      return 'Authorisation Error'
    case 500:
      return 'Server Error'
    default:
      return errorMessage || 'Unknown Error'
  }
}

const state = {
  caseStudiesLoading: false,
  loadingCaseStudiesFailed: false,
  loadingCaseStudiesErrorMessage: '',
  loadingCaseStudiesErrorDetail: '',
  caseStudies: [],
}

const mutations = {
  setCaseStudiesLoading(state, loading) {
    state.caseStudiesLoading = loading
  },
  setCaseStudies(state, studies) {
    state.caseStudies = studies
  },
  setLoadingCaseStudiesFailed(state, status) {
    state.loadingCaseStudiesFailed = status
  },
  setLoadingCaseStudiesErrorMessage(state, message) {
    state.loadingCaseStudiesErrorMessage = message
    // console.log(message)
  },
  setLoadingCaseStudiesErrorDetail(state, message) {
    state.loadingCaseStudiesErrorDetail = message
    // console.log(message)
  },
}

const actions = {
  loadCaseStudies(context) {
    context.commit('setCaseStudiesLoading', true)
    context.commit('setLoadingCaseStudiesFailed', false)
    context.commit('setLoadingCaseStudiesErrorMessage', null)
    context.commit('setLoadingCaseStudiesErrorDetail', null)
    arRisk.getStandards().then((result) => {
      // console.log(result)
      if (result.ok) {
        context.commit('setCaseStudiesLoading', false)
        context.commit('setCaseStudies', result.body)
        context.commit('setLoadingCaseStudiesErrorMessage', '')
        context.commit('setLoadingCaseStudiesErrorDetail', '')
        context.commit('setLoadingCaseStudiesFailed', false)
      } else {
        context.commit('setCaseStudiesLoading', false)
        context.commit(
          'setLoadingCaseStudiesErrorMessage',
          getErrorMessage(result.status, result.body)
        )
        context.commit('setLoadingCaseStudiesErrorDetail', result.body)
        context.commit('setLoadingCaseStudiesFailed', true)
      }
    })
  },
}

export default {
  namespaced: true,
  modules: {},
  state,
  // getters,
  actions,
  mutations,
}
