import Vue from 'vue'

let arRisk = new Vue({
  methods: {
    calculateRisk(fibData) {
      // console.log('Argon Risk calculateRisk')
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/xe/v1/PFib/calc', fibData)
        .then(
          (response) => {
            return response.body
          },
          (response) => {
            // error callback
            return response
            // console.log(response)
          }
        )
    },

    calculateCoincidence(coincData) {
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/xe/v1/PCoinc/calc', coincData)
        .then(
          (response) => {
            // get body data
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },
    calculateCoincidenceDetail(coincData) {
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/xe/v1/PCoinc/detail', coincData)
        .then(
          (response) => {
            // get body data
            return response
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    calculateMaxTouchVoltage: function (data) {
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/xe/v1/VAllow/calcSingle', data)
        .then(
          (response) => {
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    calculateMaxTouchVoltageCurve: function (inputs) {
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/xe/v1/VAllow/calcCurve', inputs)
        .then(
          (response) => {
            return response
          },
          (response) => {
            // error callback
            return response
          }
        )
    },
    getStandards: function () {
      return this.$http
        .get(process.env.VUE_APP_API_URI + '/xe/v1/caseStudiesForStandards')
        .then(
          (response) => {
            return response
          },
          (response) => {
            return response
          }
        )
    },
  },
})

export default arRisk
