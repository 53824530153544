import Vue from 'vue'
import Vuex from 'vuex'
import { saveAs } from 'file-saver/FileSaver'
import calculations from './modules/calculations'
import caseStudies from './modules/caseStudies'
import createDeepCopy from '@/helpers/deepCopy.js'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const actions = {
  saveFile(context) {
    // console.log('Save file function')
    var saveData = {
      fileInfo: {
        type: 'Argonium',
        version: 1,
      },
      fibrillationScenario:
        context.state.calculations.inputData.fibrillationData,
      exposureScenario: context.state.calculations.inputData.coincidenceData,
      hazard: context.state.calculations.inputData.hazardData,
      riskAnalysis: context.state.calculations.inputData.riskAnalysis,
    }
    var text = JSON.stringify(saveData)
    // console.log(this.inputs)
    var filename = 'Risk_Assessment'
    var blob = new Blob([text], { type: 'application/json;charset=utf-8' })
    saveAs(blob, filename + '.json')
  },
  loadFile(context, data) {
    var fileData = JSON.parse(data)
    // console.log(fileData)
    context.commit(
      'calculations/inputData/setFibrillationData',
      createDeepCopy(fileData.fibrillationScenario)
    )
    context.commit(
      'calculations/inputData/setCoincidenceData',
      createDeepCopy(fileData.exposureScenario)
    )
    context.commit(
      'calculations/inputData/setHazardData',
      createDeepCopy(fileData.hazard)
    )
    context.commit(
      'calculations/inputData/setRiskLimits',
      createDeepCopy(fileData.riskAnalysis)
    )
  },
}

export default new Vuex.Store({
  modules: {
    calculations,
    caseStudies,
  },
  actions,
  strict: debug,
})
