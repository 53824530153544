import auth0 from 'auth0-js'
import Vue from 'vue'

// exchange the object with your own from the setup step above.
let webAuth = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENT,
  redirectUri: process.env.VUE_APP_AUTH0_CALLBACK_URI,
  responseType: 'token id_token',
  scope: 'openid profile email calc:risk',
  audience: process.env.VUE_APP_AUTH0_API,
})

let auth = new Vue({
  computed: {
    token: {
      cache: false,
      get: function () {
        return localStorage.getItem('id_token')
      },
      set: function (idToken) {
        localStorage.setItem('id_token', idToken)
      },
    },
    accessToken: {
      cache: false,
      get: function () {
        return localStorage.getItem('access_token')
      },
      set: function (accessToken) {
        localStorage.setItem('access_token', accessToken)
      },
    },
    expiresAt: {
      cache: false,
      get: function () {
        return localStorage.getItem('expires_at')
      },
      set: function (expiresIn) {
        let expiresAt = JSON.stringify(expiresIn * 1000 + new Date().getTime())
        localStorage.setItem('expires_at', expiresAt)
      },
    },
    user: {
      cache: false,
      get: function () {
        return JSON.parse(localStorage.getItem('user'))
      },
      set: function (user) {
        localStorage.setItem('user', JSON.stringify(user))
      },
    },
    authenticated: {
      cache: false,
      get: function () {
        // console.log('checking authentication...')
        var nowTime = new Date().getTime()
        // console.log('now: ' + nowTime + ' -- exp_at: ' + this.expiresAt)
        return nowTime < this.expiresAt
      },
    },
    gracePeriod: {
      cache: false,
      get: function () {
        return this.user['https://aeid.apps/grace_period'] === true
      },
    },
    graceUntil: {
      cache: false,
      get: function () {
        return this.user['https://aeid.apps/grace_until']
      },
    },
  },
  methods: {
    login() {
      webAuth.authorize()
    },
    forceLogin() {
      webAuth.authorize({ prompt: 'login' })
    },
    logout() {
      return new Promise(() => {
        localStorage.removeItem('access_token')
        localStorage.removeItem('id_token')
        localStorage.removeItem('expires_at')
        localStorage.removeItem('user')

        webAuth.logout({
          returnTo: process.env.VUE_APP_AUTH0_LOGOUT_URI,
        })

        // TODO: is this the right function?
        // webAuth.authorize()
        // TODO: maybe this: https://auth0.com/docs/libraries/auth0js/v9#logout
        this.$bus.$emit('auth-change')
      })
    },
    isAuthenticated() {
      // console.log('isAuthenticated()...')
      var nowTime = new Date().getTime()
      // console.log('now: ' + nowTime + ' -- exp_at: ' + this.expiresAt)
      return nowTime < this.expiresAt
    },
    handleAuthentication() {
      return new Promise((resolve, reject) => {
        webAuth.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            this.expiresAt = authResult.expiresIn
            this.accessToken = authResult.accessToken
            this.token = authResult.idToken
            this.user = authResult.idTokenPayload
            resolve()
          } else if (err) {
            // this.logout()
            // console.log(err)
            reject(err)
          }
        })
      })
    },
  },
})

export default {
  install: function (Vue) {
    Vue.prototype.$auth = auth
  },
}
