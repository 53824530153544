<template>
  <div>
    <div v-if="loading">
      <font-awesome-icon icon="spinner" class="fa-spin" />
    </div>
    <b-alert variant="warning" :show="!loading && standards.length > 0"
      >Applying a case study will overwrite <i>all</i>
      the input values for the hazard assessment and design curve.
    </b-alert>
    <b-card
      no-body
      v-for="standard in standards"
      v-bind:key="standard.id"
      header-border-variant="secondary"
      border-variant="secondary"
      class="standard-case-studies-card mb-3"
      body-class="pt-2 pb-1 px-2"
      header-class="font-weight-bold text-capitalize"
    >
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-btn block href="#" v-b-toggle="'accordion' + standard.id">{{
          standard.name
        }}</b-btn>
      </b-card-header>

      <b-collapse
        :id="'accordion' + standard.id"
        accordion="standardsaccordion"
        role="tabpanel"
      >
        <b-card-body>
          <b-button
            v-for="dataset in standard.caseStudies"
            v-bind:key="dataset.name"
            variant="outline-primary"
            class="my-1"
            block
            v-b-tooltip.hover="{
              title: dataset.description,
              delay: { show: 400, hide: 100 },
            }"
            v-on:click="applyCaseStudy(dataset)"
          >
            {{ dataset.name }}
          </b-button>
        </b-card-body>
      </b-collapse>
    </b-card>

    <error-message
      id="case-studies-error"
      title="Loading Case Studies Failed"
      :show="loadingFailed"
      :message="errorMessage"
      :errorDetail="errorDetail"
      @retry="loadCaseStudies"
    />
  </div>
</template>

<script>
import ErrorMessage from '@/components/ErrorMessage.vue'
// import SectionBCard from '@/components/SectionBCard.vue'

export default {
  components: {
    ErrorMessage,
    // SectionBCard,
  },
  data: function () {
    return {}
  },
  computed: {
    standards: {
      get() {
        return this.$store.state.caseStudies.caseStudies
      },
    },
    loading: {
      get() {
        return this.$store.state.caseStudies.caseStudiesLoading
      },
    },
    loadingFailed: {
      get() {
        return this.$store.state.caseStudies.loadingCaseStudiesFailed
      },
    },
    errorMessage: {
      get() {
        return this.$store.state.caseStudies.loadingCaseStudiesErrorMessage
      },
    },
    errorDetail: {
      get() {
        return this.$store.state.caseStudies.loadingCaseStudiesErrorDetail
      },
    },
  },
  mounted: function () {
    this.loadCaseStudies()
  },
  methods: {
    applyCaseStudy: function (caseStudy) {
      this.$store.commit('calculations/inputData/setCaseStudy', caseStudy)
      // This is to close the window
      this.$emit('update-data')
    },
    loadCaseStudies: function () {
      this.$store.dispatch('caseStudies/loadCaseStudies')
    },
  },
}
</script>

<style></style>
