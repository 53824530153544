const state = {
  pCoincCalculating: false,
  pFibCalculating: false,
  curveCalculating: false,
  pCoincFailed: false,
  pCoincErrorMessage: '',
  pCoincErrorDetail: '',
  pFibFailed: false,
  pFibErrorMessage: '',
  pFibErrorDetail: '',
  curveFailed: false,
  curveErrorMessage: '',
  curveErrorDetail: '',
}

const mutations = {
  setPCoincCalculating(state, calcStatus) {
    state.pCoincCalculating = calcStatus
  },
  setPFibCalculating(state, calcStatus) {
    state.pFibCalculating = calcStatus
  },
  setCurveCalculating(state, calcStatus) {
    state.curveCalculating = calcStatus
  },
  setPCoincFailed(state, calcStatus) {
    state.pCoincFailed = calcStatus
  },
  setPCoincErrorMessage(state, message) {
    state.pCoincErrorMessage = message
  },
  setPCoincErrorDetail(state, message) {
    state.pCoincErrorDetail = message
  },
  setPFibFailed(state, calcStatus) {
    state.pFibFailed = calcStatus
  },
  setPFibErrorMessage(state, message) {
    state.pFibErrorMessage = message
  },
  setPFibErrorDetail(state, message) {
    state.pFibErrorDetail = message
  },
  setCurveFailed(state, calcStatus) {
    state.curveFailed = calcStatus
  },
  setCurveErrorMessage(state, message) {
    state.curveErrorMessage = message
  },
  setCurveErrorDetail(state, message) {
    state.curveErrorDetail = message
  },
}

export default {
  namespaced: true,
  // modules: {
  //   results,
  //   inputData
  // },
  state,
  // getters,
  // actions,
  mutations,
}
