var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('b-navbar',{attrs:{"type":"dark","variant":"primary","toggleable":"sm","id":"main-navbar"}},[_c('b-navbar-brand',{attrs:{"to":"/"}},[_c('font-awesome-icon',{attrs:{"icon":"heartbeat"}}),_vm._v(" "),_c('b',[_vm._v(" "+_vm._s(this.PRODUCT_NAME))])],1),_c('b-navbar-toggle',{attrs:{"target":"nav_collapse"}}),_c('b-collapse',{attrs:{"is-nav":"","id":"nav_collapse"}},[(this.isLoggedIn)?_c('b-navbar-nav',[_c('b-nav-item',{attrs:{"to":"/calc"}},[_vm._v("Risk Calculator")]),(
            this.$route.path == '/hazard' ||
            this.$route.path == '/curve' ||
            this.$route.path == '/calc'
          )?_c('b-nav-form',[_c('b-button-group',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Save File',
                delay: { show: 400, hide: 100 },
              }),expression:"{\n                title: 'Save File',\n                delay: { show: 400, hide: 100 },\n              }",modifiers:{"hover":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.saveFile}},[_c('font-awesome-icon',{attrs:{"icon":"file-download"}})],1),_c('file-reader',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Load File',
                delay: { show: 400, hide: 100 },
              }),expression:"{\n                title: 'Load File',\n                delay: { show: 400, hide: 100 },\n              }",modifiers:{"hover":true}}],on:{"load":_vm.loadFile}},[_c('font-awesome-icon',{attrs:{"icon":"folder-open"}})],1)],1),_c('b-button-group',{staticClass:"mx-2"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.loadPresetModal",modifiers:{"loadPresetModal":true}},{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Load Preset',
                delay: { show: 400, hide: 100 },
              }),expression:"{\n                title: 'Load Preset',\n                delay: { show: 400, hide: 100 },\n              }",modifiers:{"hover":true}}],attrs:{"variant":"outline-secondary"}},[_c('font-awesome-icon',{attrs:{"icon":"book"}})],1)],1),_c('b-button-group',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                title: 'Recalculate All',
                delay: { show: 400, hide: 100 },
              }),expression:"{\n                title: 'Recalculate All',\n                delay: { show: 400, hide: 100 },\n              }",modifiers:{"hover":true}}],attrs:{"variant":"outline-secondary"},on:{"click":_vm.recalculateAll}},[_c('font-awesome-icon',{attrs:{"icon":"redo"}})],1)],1)],1):_vm._e()],1):_vm._e(),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-form',[(this.isLoggedIn)?_c('profile-menu'):_c('b-button',{attrs:{"variant":"outline-light"},on:{"click":_vm.login}},[_vm._v(" Log In ")])],1)],1)],1)],1),_c('keep-alive',[_c('router-view',{attrs:{"loggedIn":this.isLoggedIn}})],1),_c('b-modal',{ref:"loadPresetRef",attrs:{"id":"loadPresetModal","title":"Load Case Study Preset","size":"lg","ok-only":"","ok-title":"Close","lazy":""}},[_vm._v(" To provide a convenient starting point for a site-specific risk assessment you can apply the input values from the case studies in various Standards. "),_c('p'),_c('p',[_vm._v(" These presets are intended to be used in conjuction with the relevant standards. Before using them, refer to the source document(s) for details, and make sure you understand the intended application. ")]),_c('case-studies',{on:{"update-data":_vm.applyCaseStudy}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }