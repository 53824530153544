import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/calc',
      name: 'newUI',
      component: () =>
        import(/* webpackChunkName: "calc" */ './views/NewCalculatorUI.vue'),
    },
    {
      path: '/auth-callback',
      name: 'auth-callback',
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/AuthCallback.vue'),
    },
    {
      path: '/auth-error',
      name: 'auth-error',
      props: true,
      component: () =>
        import(/* webpackChunkName: "auth" */ '@/views/AuthError.vue'),
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
})

router.beforeEach((to, from, next) => {
  // Allow access to the "authentication callback"
  if (to.name === 'auth-callback' || to.name === 'auth-error') {
    // console.log('Allowing access to auth-callback')
    next()
  } else if (router.app.$auth.authenticated) {
    // if you're authenticated, then we'll let anything go...
    // console.log('Authenticated, allowing navigation to: ' + to.path)
    next()
  } else {
    // we're going to force you to log in...
    if (to.path === '/') {
      next()
    } else {
      // console.log('Not Authenticated! Denying access to: ' + to.path)
      next('/')
    }
  }
})

export default router
