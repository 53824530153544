<template>
  <label class="text-reader btn btn-outline-secondary">
    <!-- Open File -->
    <slot></slot>
    <input type="file" @change="loadTextFromFile" />
  </label>
</template>

<script>
export default {
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0]
      // console.log(file)
      const reader = new FileReader()
      reader.onload = (e) => this.$emit('load', e.target.result)
      reader.readAsText(file)
    },
  },
}
</script>

<style>
/* .text-reader { */
/* position: relative;
  overflow: hidden;
  display: inline-block; */

/* Fancy button style 😎 */
/* border: 1px solid #CED2D7;
  background: rgba(255, 255, 255, 0);
  border-radius: 5px;
  padding: 4px 8px;
  margin: 0px 0px;
  cursor: pointer; */
/* } */
.text-reader input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* opacity: 0; */
  display: none;
}
</style>
