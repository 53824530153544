import inputData from './inputData'
import calcStatus from './calcStatus'
import results from './results'
import arRisk from '@/comms/ArgonRisk.js'

const getErrorMessage = function (errorCode, errorMessage) {
  switch (errorCode) {
    // Can't reach server
    case 0:
    case 404: // Incorrect endpoint name etc
      return 'Comms Error'
    case 401:
      return 'Authorisation Error'
    case 500:
      return 'Server Error'
    default:
      return errorMessage || 'Unknown Error'
  }
}

const actions = {
  clearPFib(context) {
    context.commit('results/setProbFib', 0)
  },
  clearPCoinc(context) {
    context.commit('results/setProbCoinc', 0)
  },
  clearCurveData(context) {
    context.commit('results/clearDataPoints')
  },
  calculateRisk(context) {
    arRisk
      .calculateRisk(context.getters['inputData/fibrillationInputData'])
      .then((result) => {
        // console.log(result)
        if (typeof result === 'number') {
          context.commit('calcStatus/setPFibFailed', false)
          context.commit('results/setProbFib', result)
          context.commit('calcStatus/setPFibCalculating', false)
        } else {
          context.commit('calcStatus/setPFibFailed', true)
          context.commit('calcStatus/setPFibCalculating', false)
          context.commit(
            'calcStatus/setPFibErrorMessage',
            getErrorMessage(result.status, result.body)
          )
          context.commit('calcStatus/setPFibErrorDetail', result.body)
        }
      })
  },
  calculateCoincidence(context) {
    arRisk
      .calculateCoincidenceDetail(
        context.getters['inputData/coincidenceInputData']
      )
      .then((result) => {
        if (result.ok) {
          context.commit('calcStatus/setPCoincFailed', false)
          context.commit('results/setProbCoinc', result.body.pCoinc)
          context.commit('results/setProbFault', result.body.pFault)
          context.commit('results/setProbContact', result.body.pContact)
          context.commit('calcStatus/setPCoincCalculating', false)
        } else {
          context.commit('calcStatus/setPCoincFailed', true)
          context.commit('calcStatus/setPCoincCalculating', false)
          context.commit(
            'calcStatus/setPCoincErrorMessage',
            getErrorMessage(result.status, result.body)
          )
          context.commit('calcStatus/setPCoincErrorDetail', result.body)
        }
      })
  },
  calculateMaxTouchVoltageCurve(context) {
    // Create data structure
    var inputData = context.getters['inputData/maxVTCurveInputData']
    context.commit('calcStatus/setCurveCalculating', true)
    context.commit('calcStatus/setCurveFailed', false)
    context.commit('calcStatus/setCurveErrorMessage', '')
    context.commit('calcStatus/setCurveErrorDetail', '')
    context.commit('results/clearDataPoints')
    arRisk.calculateMaxTouchVoltageCurve(inputData).then((result) => {
      if (result.ok) {
        context.commit('calcStatus/setCurveFailed', false)
        var voltagesAr = result.body
        var i
        var arDataPoints = []
        for (i = 0; i < voltagesAr.length; i++) {
          arDataPoints[i] = { x: inputData.times[i], y: voltagesAr[i] }
        }
        context.commit('results/setPoints', arDataPoints)
        context.commit('calcStatus/setCurveCalculating', false)
        context.commit('calcStatus/setCurveFailed', false)
        context.commit('calcStatus/setCurveErrorMessage', '')
        context.commit('calcStatus/setCurveErrorDetail', '')
      } else {
        context.commit('calcStatus/setCurveFailed', true)
        context.commit('calcStatus/setCurveCalculating', false)
        context.commit(
          'calcStatus/setCurveErrorMessage',
          getErrorMessage(result.status, result.body)
        )
        context.commit('calcStatus/setCurveErrorDetail', result.body)
      }
    })
  },
}

export default {
  namespaced: true,
  modules: {
    calcStatus,
    inputData,
    results,
  },
  // state,
  // getters,
  actions,
  // mutations
}
