<template>
  <div class="home">
    <b-container>
      <b-row align-h="center">
        <b-col cols="11" md="8">
          <b-card
            :title="this.PRODUCT_NAME"
            sub-title="A free tool for earthing related Quantitative Risk Assessments"
            class="my-4"
            border-variant="primary"
            header-bg-variant="primary"
          >
            <p class="card-text">
              This site provides a free earthing related Quantified Risk
              Analysis (QRA) tool intended to support the Cigre Technical
              Brochure publication from the <i>Joint Working Group B3.35</i>. It
              has been made available at the request of the Study Committee B3
              Chairman to ensure the ongoing availability of a tool to provide a
              point of reference.
            </p>
            <p class="card-text">
              It is based on the same methods and source data published in the
              Australian ENA document EG0 and used by the ENA tool called
              <i>Argon</i>.
            </p>

            <div slot="footer">
              <small>
                For further information please email
                <b-link href="mailto:enquiries@argonium.com.au">
                  enquiries@argonium.com.au
                </b-link>
              </small>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-alert :show="!loggedIn" variant="warning" fade>
            <h4 class="alert-heading">You need to log in</h4>

            {{ PRODUCT_NAME }} is free to use, but first you'll need to
            <b-button @click="login" variant="outline-warning">
              Log-In / Sign-Up
            </b-button>
          </b-alert>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      PRODUCT_NAME: process.env.VUE_APP_PRODUCT_NAME,
    }
  },
  props: {
    loggedIn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    login: function () {
      this.$auth.forceLogin()
    },
  },
}
</script>
