// import Vue from 'vue'

const state = {
  fibrillationData: {
    surface: 'None',
    resistivity: 50,
    footwear: 'EG0_Standard',
    footwearCondition: 'Dry',
    voltagePath: 'Touch',
  },
  coincidenceData: {
    faultRate: 10,
    contactRate: 416,
    contactDuration: 4,
  },
  hazardData: {
    clearingTime: 0.5,
    voltage: 100,
  },
  riskAnalysis: {
    negligibleLevel: 1e-6,
    intolerableLevel: 1e-4,
  },
  curveData: {
    targetRisk: 1e-6,
    minVoltage: 0,
    maxVoltage: 10000,
    voltageTolerance: 0.1,
    times: [
      0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 2, 2.2, 2.5, 3,
      3.2, 3.5, 4, 4.2, 4.5, 5, 6, 7, 8, 9, 10,
    ],
  },
}

const getters = {
  maxVTCurveInputData: (state) => {
    var curveInputData = {
      faultRate: state.coincidenceData.faultRate,
      contactRate: state.coincidenceData.contactRate,
      contactDuration: state.coincidenceData.contactDuration,
      surface: state.fibrillationData.surface,
      resistivity: state.fibrillationData.resistivity,
      footwear: state.fibrillationData.footwear,
      footwearCondition: state.fibrillationData.footwearCondition,
      voltagePath: state.fibrillationData.voltagePath,
      targetRisk: state.riskAnalysis.negligibleLevel,
      minVoltage: state.curveData.minVoltage,
      maxVoltage: state.curveData.maxVoltage,
      voltageTolerance: state.curveData.voltageTolerance,
      times: state.curveData.times,
    }
    return curveInputData
  },
  fibrillationInputData: (state) => {
    var inputData = {
      clearingTime: state.hazardData.clearingTime,
      voltage: state.hazardData.voltage,
      surface: state.fibrillationData.surface,
      resistivity: state.fibrillationData.resistivity,
      footwear: state.fibrillationData.footwear,
      footwearCondition: state.fibrillationData.footwearCondition,
      voltagePath: state.fibrillationData.voltagePath,
    }
    return inputData
  },
  coincidenceInputData: (state) => {
    var inputData = {
      clearingTime: state.hazardData.clearingTime,
      faultRate: state.coincidenceData.faultRate,
      contactRate: state.coincidenceData.contactRate,
      contactDuration: state.coincidenceData.contactDuration,
    }
    return inputData
  },
}

const mutations = {
  setCaseStudy(state, caseStudy) {
    state.hazardData.clearingTime = caseStudy.clearingTime
    state.hazardData.voltage = caseStudy.voltage

    state.fibrillationData.surface = caseStudy.surface
    state.fibrillationData.resistivity = caseStudy.resistivity
    state.fibrillationData.footwear = caseStudy.footwear
    state.fibrillationData.footwearCondition = caseStudy.bodyCondition
    state.fibrillationData.voltagePath = caseStudy.voltagePath

    state.coincidenceData.faultRate = caseStudy.faultRate
    state.coincidenceData.clearingTime = caseStudy.clearingTime
    state.coincidenceData.contactRate = caseStudy.contactRate
    state.coincidenceData.contactDuration = caseStudy.contactDuration
  },
  setFibrillationData(state, fibData) {
    state.fibrillationData.surface = fibData.surface
    // state.fibrillationData.clearingTime = fibData.clearingTime
    // state.fibrillationData.voltage = fibData.voltage
    state.fibrillationData.resistivity = fibData.resistivity
    state.fibrillationData.footwear = fibData.footwear
    state.fibrillationData.footwearCondition = fibData.footwearCondition
    state.fibrillationData.voltagePath = fibData.voltagePath
  },
  setHazardData(state, hazData) {
    state.hazardData.clearingTime = hazData.clearingTime
    state.hazardData.voltage = hazData.voltage
  },
  setSurface(state, surface) {
    state.fibrillationData.surface = surface
  },
  setClearingTime(state, clearingTime) {
    state.hazardData.clearingTime = clearingTime
  },
  setVoltage(state, voltage) {
    state.hazardData.voltage = voltage
  },
  setResistivity(state, resistivity) {
    state.fibrillationData.resistivity = resistivity
  },
  setFootwear(state, footwear) {
    state.fibrillationData.footwear = footwear
  },
  setFootwearCondition(state, footwearCondition) {
    state.fibrillationData.footwearCondition = footwearCondition
  },
  setVoltagePath(state, voltagePath) {
    state.fibrillationData.voltagePath = voltagePath
  },
  setCoincidenceData(state, coincData) {
    state.coincidenceData.faultRate = coincData.faultRate
    state.coincidenceData.contactRate = coincData.contactRate
    state.coincidenceData.contactDuration = coincData.contactDuration
  },
  setFaultRate(state, faultRate) {
    state.coincidenceData.faultRate = faultRate
  },
  setContactRate(state, contactRate) {
    state.coincidenceData.contactRate = contactRate
  },
  setContactDuration(state, contactDuration) {
    state.coincidenceData.contactDuration = contactDuration
  },
  setRiskLimits(state, limits) {
    state.riskAnalysis.negligibleLevel = limits.negligibleLevel
    state.riskAnalysis.intolerableLevel = limits.intolerableLevel
  },
  setNegligibleLevel(state, negligibleLevel) {
    state.riskAnalysis.negligibleLevel = negligibleLevel
    if (
      state.riskAnalysis.negligibleLevel >= state.riskAnalysis.intolerableLevel
    ) {
      state.riskAnalysis.intolerableLevel = parseFloat(
        (state.riskAnalysis.negligibleLevel * 10).toPrecision(1)
      )
    }
  },
  setIntolerableLevel(state, intolerableLevel) {
    state.riskAnalysis.intolerableLevel = intolerableLevel
    if (
      state.riskAnalysis.negligibleLevel >= state.riskAnalysis.intolerableLevel
    ) {
      state.riskAnalysis.negligibleLevel = parseFloat(
        (state.riskAnalysis.intolerableLevel * 0.1).toPrecision(1)
      )
    }
  },
}

export default {
  namespaced: true,
  // modules: {
  //   results,
  //   inputData
  // },
  state,
  getters,
  // actions,
  mutations,
}
