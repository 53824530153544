import Vue from 'vue'

let seRisk = new Vue({
  // data: function () {
  //   return {
  //     Config: {
  //       hostname: 'https://api-risk.safearth.team'
  //     }
  //   }
  // },

  methods: {
    calculateRisk(fibData) {
      // console.log('Safearth Risk calculateRisk')
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/se/v1/PFib/calc', fibData)
        .then(
          (response) => {
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    calculateCoincidence(coincData) {
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/se/v1/PCoinc/calc', coincData)
        .then(
          (response) => {
            // get body data
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    calculateMaxTouchVoltage: function (data) {
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/se/v1/VtMax/calc', data)
        .then(
          (response) => {
            // get body data
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    calculateMaxTouchVoltageCurve: function (inputs) {
      // post
      return this.$http
        .post(process.env.VUE_APP_API_URI + '/se/v1/VtMax/calcCurve', inputs)
        .then(
          (response) => {
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },

    getCaseStudies: function () {
      return this.$http
        .get(process.env.VUE_APP_API_URI + '/se/v1/caseStudies')
        .then(
          (response) => {
            return response.body
          },
          (response) => {
            // error callback
            return response
          }
        )
    },
    getStandards: function () {
      return this.$http
        .get(process.env.VUE_APP_API_URI + '/se/v1/caseStudiesForStandards')
        .then(
          (response) => {
            return response
          },
          (response) => {
            return response
          }
        )
    },
  },
})

export default seRisk
