<template>
  <div id="app">
    <b-navbar type="dark" variant="primary" toggleable="sm" id="main-navbar">
      <b-navbar-brand to="/"
        ><font-awesome-icon icon="heartbeat" /> <b> {{ this.PRODUCT_NAME }}</b>
      </b-navbar-brand>
      <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

      <b-collapse is-nav id="nav_collapse">
        <b-navbar-nav v-if="this.isLoggedIn">
          <!-- <b-nav-item to="/hazard">Hazard Assessment</b-nav-item> -->
          <!-- <b-nav-item to="/curve">Design Curve</b-nav-item> -->
          <b-nav-item to="/calc">Risk Calculator</b-nav-item>

          <!-- Menu bar Icons/Buttons -->
          <b-nav-form
            v-if="
              this.$route.path == '/hazard' ||
              this.$route.path == '/curve' ||
              this.$route.path == '/calc'
            "
          >
            <b-button-group>
              <b-button
                v-on:click="saveFile"
                variant="outline-secondary"
                v-b-tooltip.hover="{
                  title: 'Save File',
                  delay: { show: 400, hide: 100 },
                }"
              >
                <font-awesome-icon icon="file-download" />
              </b-button>
              <file-reader
                v-on:load="loadFile"
                v-b-tooltip.hover="{
                  title: 'Load File',
                  delay: { show: 400, hide: 100 },
                }"
              >
                <font-awesome-icon icon="folder-open" />
              </file-reader>
            </b-button-group>

            <b-button-group class="mx-2">
              <b-button
                v-b-modal.loadPresetModal
                variant="outline-secondary"
                v-b-tooltip.hover="{
                  title: 'Load Preset',
                  delay: { show: 400, hide: 100 },
                }"
              >
                <font-awesome-icon icon="book" />
              </b-button>
            </b-button-group>
            <b-button-group>
              <b-button
                variant="outline-secondary"
                v-b-tooltip.hover="{
                  title: 'Recalculate All',
                  delay: { show: 400, hide: 100 },
                }"
                @click="recalculateAll"
              >
                <font-awesome-icon icon="redo" />
              </b-button>
            </b-button-group>
          </b-nav-form>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <profile-menu v-if="this.isLoggedIn"></profile-menu>
            <b-button v-else @click="login" variant="outline-light">
              Log In
            </b-button>
          </b-nav-form>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <keep-alive>
      <router-view :loggedIn="this.isLoggedIn" />
    </keep-alive>

    <!-- Pop-Ups / Modals -->

    <b-modal
      id="loadPresetModal"
      title="Load Case Study Preset"
      ref="loadPresetRef"
      size="lg"
      ok-only
      ok-title="Close"
      lazy
    >
      To provide a convenient starting point for a site-specific risk assessment
      you can apply the input values from the case studies in various Standards.
      <p />
      <p>
        These presets are intended to be used in conjuction with the relevant
        standards. Before using them, refer to the source document(s) for
        details, and make sure you understand the intended application.
      </p>

      <!-- <p class="font-weight-bold">Applying a case study will overwrite <i>all</i> -->

      <case-studies @update-data="applyCaseStudy"></case-studies>
    </b-modal>
  </div>
</template>

<style lang="scss">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 350px;
}
</style>

<script>
import CaseStudies from '@/components/CaseStudies.vue'
import ProfileMenu from '@/components/ProfileMenu.vue'
import FileReader from '@/components/FileReader.vue'

export default {
  name: 'RiskCalculator',
  components: {
    CaseStudies,
    ProfileMenu,
    FileReader,
  },
  data: function () {
    return {
      PRODUCT_NAME: process.env.VUE_APP_PRODUCT_NAME,
      isLoggedIn: false,
    }
  },
  methods: {
    applyCaseStudy: function () {
      // use global event bus to get data into the calculator components
      // this.$bus.$emit('apply-data', caseStudy)
      this.$refs.loadPresetRef.hide()
    },
    handleAuthChange: function () {
      // console.log('App.vue - handling an auth-change event')
      this.isLoggedIn = this.$auth.authenticated
    },
    login: function () {
      this.$auth.login()
    },
    logout: function () {
      // console.log('logout clicked...')
      this.$auth.logout()
      this.$bus.$emit('auth-change')
      this.$router.push('/')
    },
    recalculateAll: function () {
      this.$bus.$emit('recalculate')
    },
    loadFile: function (data) {
      this.$store.dispatch('loadFile', data)
    },
    saveFile: function () {
      this.$store.dispatch('saveFile')
    },
  },
  mounted: function () {
    this.isLoggedIn = this.$auth.authenticated
    this.$bus.$on('auth-change', this.handleAuthChange)
  },
}
</script>
