<template>
  <div :id="id + '-div'">
    <b-alert dismissible :show="show" variant="danger">
      <h4>{{ title }}</h4>
      <div class="d-flex justify-content-between">
        {{ message }}
        <b-button
          v-if="showDetails"
          v-b-toggle="collapseID"
          variant="outline-danger"
          size="sm"
        >
          <em>{{ detailsLabel }}</em>
        </b-button>
      </div>
      <b-collapse v-if="showDetails" :id="collapseID">
        <pre
          class="bg-light border border-danger rounded-bottom rounded-left p-2"
          >{{ errorDetail }}</pre
        >
      </b-collapse>
      <hr v-if="retryable" />
      <p v-if="retryable">
        <b-button variant="outline-danger" @click="retryClicked">{{
          retryLabel
        }}</b-button>
      </p>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    id: {
      type: String,
      required: true,
      default() {
        return 'base-error'
      },
    },
    title: {
      type: String,
      required: true,
      default() {
        return ''
      },
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    message: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    errorDetail: {
      type: String,
      required: false,
      default() {
        return ''
      },
    },
    detailsLabel: {
      type: String,
      required: false,
      default() {
        return 'Tech Details'
      },
    },
    retryable: {
      type: Boolean,
      required: false,
      default: true,
    },
    retryLabel: {
      type: String,
      required: false,
      default() {
        return 'Retry'
      },
    },
  },
  computed: {
    collapseID: {
      get() {
        return this.id + '-details'
      },
    },
    showDetails: {
      get() {
        if (this.errorDetail && this.errorDetail !== '') {
          return true
        } else {
          return false
        }
      },
    },
  },
  methods: {
    retryClicked() {
      this.$emit('retry')
    },
  },
}
</script>

<style></style>
